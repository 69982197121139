import coinage from "./solidity/artifacts/contracts/Coinage.sol/Coinage.json";
import proxy from "./solidity/artifacts/contracts/CoinageSubscriber.sol/CoinageSubscriber.json";

export const useCoinageContract = () => {
  const coinageAddress = process.env.NEXT_PUBLIC_COINAGE_ADDRESS;

  return {
    address: coinageAddress as `0x${string}`,
    abi: coinage.abi,
    chainId: 1
  };
};

export const useSubscriberProxyContract = () => {
  const add = process.env.NEXT_PUBLIC_SUBSCRIBER_PROXY_ADDRESS;

  return {
    address: add as `0x${string}`,
    abi: proxy.abi,
    chainId: 1
  };
};

export const coinageRead = {
  address: process.env.NEXT_PUBLIC_COINAGE_ADDRESS as `0x${string}`,
  abi: coinage.abi,
  chainId: 1
};
